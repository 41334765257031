import { CustomTypography } from "components/customTypography/custom-typography";
import { STitle, SSubTitle, SParag, SList, SItem } from "./styles";

export const renderPar = (par: any) => (
  <div>
    <STitle>
      <CustomTypography variant="h2_medium" font="comp">
        {par.title}
      </CustomTypography>
    </STitle>

    {par.blocks.map((block, index) => (
      <div key={`block-${par.id}-${index}`}>
        {block.subtitle ? (
          <SSubTitle>
            <CustomTypography variant="h3_medium" font="comp">
              {block.subtitle}
            </CustomTypography>
          </SSubTitle>
        ) : null}

        {block.text.map((cont, i) => {
          if (typeof cont === "string") {
            return (
              <SParag key={`parag-${par.id}-${i}`}>
                <CustomTypography variant="p3_regular" font="comp">
                  {cont}
                </CustomTypography>
              </SParag>
            );
          } else {
            return (
              <SList key={`list-${par.id}-${i}`}>
                {cont.map((item, j) => (
                  <SItem key={`item-${i}${j}`}>
                    <CustomTypography key={item} variant="p3_regular" font="comp">
                      {item}
                    </CustomTypography>
                  </SItem>
                ))}
              </SList>
            );
          }
        })}
      </div>
    ))}
  </div>
);
