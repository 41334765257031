import { Outlet, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import { BreakpointProvider, useBreakpoint } from "hooks/useBreakpoint";
import { defineHeaderView, isOnlyContentPage } from "./utils";
import { FooterFull } from "components/footer-full/footer-full";
import { ModalFormConsultation } from "components/modal-form-consultation/modal-form-consultation";
import { Header } from "components/header/header";
import { Landing } from "pages/landing/landing";
import { LandingCommercialLosses } from "pages/landingCommercialLosses/landingCommercialLosses";
import { LandingAskue } from "pages/landingAskue/landingAskue";
import { LandingMining } from "pages/landingMining/landingMining";
import { NotFound } from "pages/notFound/notFound";
import { UserAgreement } from "pages/userAgreement/userAgreement";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";

import {
  ROUTE_ASKUE_PAGE,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_CONDITION_PROMOTION,
  ROUTE_MINING_PAGE,
  ROUTE_OFFER_EQUIPMENT,
  ROUTE_OFFER_LICENSE,
  ROUTE_SHOP,
  ROUTE_SHOP_CART,
  ROUTE_SHOP_ITEM,
  ROUTE_USER_AGREEMENT,
  ROUTE_SHOP_CHECKOUT,
  ROUTE_CHECKOUT_SUCCESS,
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_INTEGRATION,
  ROUTE_ASKUE_OPP,
  ROUTE_ASKUE_DEMO,
  ROUTE_ASKUE_SNT,
} from "stream-constants/route-constants";
import { OfferEquipment } from "pages/offerEquipment/offerEquipment";
import { OfferLicense } from "pages/offerLicense/offerLicense";
import { ShopCheckout } from "pages/shopCheckout/shopCheckoutPage";
import { ContextSettings } from "context/context-settings";
import { fetchAbout, validate } from "services/auth";
import { getArrayRoles } from "utils/roles";
import { IProfileResponse } from "interface/common";
import { Shop } from "pages/shop/shopPage";
import { ShopItem } from "pages/shopItem/shopItemPage";
import { ShopCart } from "pages/shopCart/shopCartPage";
import { ShopCheckoutSuccess } from "pages/shopCheckoutSuccess/shopCheckoutPage";
import { ConditionPromotion } from "pages/conditionPromotion/conditionPromotion";
import { CostIntegration } from "pages/costIntegration/costIntegration";
import { PossibilityPlatform } from "pages/possibilityPlatform/possibilityPlatform";
import { AskueEquipment } from "pages/askueEquipment/askueEquipment";
import { DemoAskuePage } from "pages/demoAskue/demoAskuePage";
import { AskueSnt } from "pages/askueSnt/askueSnt";
import { SApp, SMain } from "./styles";

function App() {
  const { isAuthorized, setIsAuthorized, setRoles } = useContext(ContextSettings);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await validate();
        if (response.ok) {
          try {
            const prof = await fetchAbout();
            if (prof.ok) {
              const res: IProfileResponse = await prof.json();
              setIsAuthorized(true);
              if (!isEmpty(res?.roles)) getArrayRoles(res?.roles, setRoles);
            } else {
              throw new Error("profile error");
            }
          } catch (err) {
            throw new Error(err);
          }
        } else {
          throw new Error("validating error");
        }
      } catch (error) {
        setIsAuthorized(false);
      }
    };

    checkAuth();
  }, [setIsAuthorized, setRoles]);

  return (
    <SApp>
      <BreakpointProvider>
        <Routes>
          <Route element={<ProtectedRoute isAuthorized />}>
            <Route index element={<Landing />} />
            <Route
              path={`/${ROUTE_COMMERCIAL_LOSSES_PAGE}`}
              element={<LandingCommercialLosses />}
            />
            <Route path={`/${ROUTE_ASKUE_PAGE}`} element={<LandingAskue />} />
            <Route path={`/${ROUTE_MINING_PAGE}`} element={<LandingMining />} />
            <Route path={`/${ROUTE_USER_AGREEMENT}`} element={<UserAgreement />} />
            <Route path={`/${ROUTE_OFFER_EQUIPMENT}`} element={<OfferEquipment />} />
            <Route path={`/${ROUTE_OFFER_LICENSE}`} element={<OfferLicense />} />
            <Route path={`/${ROUTE_CONDITION_PROMOTION}`} element={<ConditionPromotion />} />
            <Route path={`/${ROUTE_SHOP}`} element={<Shop />} />
            <Route path={`/${ROUTE_SHOP_ITEM}`} element={<ShopItem />} />
            <Route path={`/${ROUTE_SHOP_CART}`} element={<ShopCart />} />
            <Route path={`/${ROUTE_ASKUE_EQUIPMENT}`} element={<AskueEquipment />} />
            <Route path={`/${ROUTE_ASKUE_INTEGRATION}`} element={<CostIntegration />} />
            <Route path={`/${ROUTE_ASKUE_OPP}`} element={<PossibilityPlatform />} />
            <Route path={`/${ROUTE_ASKUE_DEMO}`} element={<DemoAskuePage />} />
            <Route path={`/${ROUTE_ASKUE_SNT}`} element={<AskueSnt />} />
          </Route>

          <Route element={<ProtectedRoute isAuthorized={isAuthorized} />}>
            <Route path={`/${ROUTE_SHOP_CHECKOUT}`} element={<ShopCheckout />} />
            <Route path={`/${ROUTE_CHECKOUT_SUCCESS}`} element={<ShopCheckoutSuccess />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BreakpointProvider>
    </SApp>
  );
}

const MainLayout = () => {
  const { pathname } = useLocation();
  const { isTablet } = useBreakpoint();

  return (
    <>
      <div>
        {isOnlyContentPage(pathname) ? (
          <Header view={defineHeaderView(pathname, isTablet())} />
        ) : null}
        <SMain>
          <Outlet />
        </SMain>
        {isOnlyContentPage(pathname) ? <FooterFull /> : null}
      </div>
      <ScrollToTop />
      <ModalFormConsultation />
    </>
  );
};

interface IProtRoute {
  isAuthorized: boolean;
}

const ProtectedRoute: React.FC<IProtRoute> = ({ isAuthorized }) => {
  return isAuthorized ? <MainLayout /> : <Navigate replace to={ROUTE_SHOP} />;
};

export default App;
