export const ROUTE_MAIN_PAGE = "/";
export const ROUTE_COMMERCIAL_LOSSES_PAGE = "commercial_losses";
export const ROUTE_ASKUE_PAGE = "askue";
export const ROUTE_MINING_PAGE = "mining";
export const ROUTE_USER_AGREEMENT = "user-agreement";
export const ROUTE_OFFER_EQUIPMENT = "offer-equipment";
export const ROUTE_OFFER_LICENSE = "offer-license";
export const ROUTE_CONDITION_PROMOTION = "condition-promotion";
export const ROUTE_ASKUE_OPP = `${ROUTE_ASKUE_PAGE}/opportunities`;
export const ROUTE_ASKUE_EQUIPMENT = `${ROUTE_ASKUE_PAGE}/equipment`;
export const ROUTE_ASKUE_INTEGRATION = `${ROUTE_ASKUE_PAGE}/price`;
export const ROUTE_ASKUE_DEMO = `test`;
export const ROUTE_ASKUE_SNT = `snt`;

export const ROUTE_SHOP = "shop";
export const ROUTE_SHOP_ITEM = `${ROUTE_SHOP}/:id`;
export const ROUTE_SHOP_CART = "cart";
export const ROUTE_SHOP_CHECKOUT = `${ROUTE_SHOP}/checkout`;
export const ROUTE_CHECKOUT_SUCCESS = `${ROUTE_SHOP}/checkout/success`;

export const ROUTE_MAILTO = "mailto:support@energytool.mts.ru";
