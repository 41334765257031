import { titleByRoute } from "components/header/components/desk-menu/const";
import {
  ROUTE_MAIN_PAGE,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_ASKUE_PAGE,
  ROUTE_ASKUE_OPP,
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_INTEGRATION,
  ROUTE_ASKUE_SNT,
  ROUTE_MINING_PAGE,
  ROUTE_SHOP,
  ROUTE_USER_AGREEMENT,
  ROUTE_OFFER_EQUIPMENT,
  ROUTE_OFFER_LICENSE,
} from "stream-constants/route-constants";

export const mobileMenuLinks: Array<{
  link: string;
  title: string;
  subitem?: Array<{ link: string; title: string }>;
}> = [
  {
    link: `${ROUTE_MAIN_PAGE}`,
    title: "Главная страница",
  },
  {
    link: `${ROUTE_COMMERCIAL_LOSSES_PAGE}`,
    title: "Поиск коммерческих потерь",
  },
  {
    link: `${ROUTE_ASKUE_PAGE}`,
    title: "Облачная платформа учета",
    subitem: [
      { link: ROUTE_ASKUE_OPP, title: titleByRoute[ROUTE_ASKUE_OPP] },
      { link: ROUTE_ASKUE_EQUIPMENT, title: titleByRoute[ROUTE_ASKUE_EQUIPMENT] },
      { link: ROUTE_ASKUE_INTEGRATION, title: titleByRoute[ROUTE_ASKUE_INTEGRATION] },
      { link: ROUTE_ASKUE_SNT, title: titleByRoute[ROUTE_ASKUE_SNT] },
    ],
  },
  {
    link: `${ROUTE_MINING_PAGE}`,
    title: "Выявление майнинговых ферм",
  },
  {
    link: `${ROUTE_SHOP}`,
    title: "Магазин",
  },
  {
    link: `${ROUTE_USER_AGREEMENT}`,
    title: "Обработка персональных данных",
  },
  {
    link: `${ROUTE_OFFER_EQUIPMENT}`,
    title: "Оферта на поставку оборудования",
  },
  {
    link: `${ROUTE_OFFER_LICENSE}`,
    title: "Оферта на лицензии EnergyTool",
  },
];
