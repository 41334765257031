import {
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_INTEGRATION,
  ROUTE_ASKUE_OPP,
  ROUTE_ASKUE_PAGE,
  ROUTE_ASKUE_SNT,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_MINING_PAGE,
} from "stream-constants/route-constants";

export const mainOptions = [
  {
    route: ROUTE_COMMERCIAL_LOSSES_PAGE,
    name: "Поиск коммерческих потерь",
  },
  {
    route: ROUTE_ASKUE_PAGE,
    name: "Облачная платформа учета",
  },
  {
    route: ROUTE_MINING_PAGE,
    name: "Выявление майнинговых ферм",
  },
];

export const titleByRoute = {
  [ROUTE_ASKUE_OPP]: "Возможности платформы",
  [ROUTE_ASKUE_EQUIPMENT]: "Поддерживаемые виды связи и оборудование",
  [ROUTE_ASKUE_INTEGRATION]: "Стоимость внедрения АСКУЭ",
  [ROUTE_ASKUE_SNT]: "АСКУЭ для CHT",
};

export const subsByMain = {
  [ROUTE_COMMERCIAL_LOSSES_PAGE]: null,
  [ROUTE_ASKUE_PAGE]: [
    { route: ROUTE_ASKUE_OPP, name: titleByRoute[ROUTE_ASKUE_OPP] },
    { route: ROUTE_ASKUE_EQUIPMENT, name: titleByRoute[ROUTE_ASKUE_EQUIPMENT] },
    { route: ROUTE_ASKUE_INTEGRATION, name: titleByRoute[ROUTE_ASKUE_INTEGRATION] },
    { route: ROUTE_ASKUE_SNT, name: titleByRoute[ROUTE_ASKUE_SNT] },
  ],
  [ROUTE_MINING_PAGE]: null,
};

export const urlsToCheck = mainOptions.map((a) => a.route);

export const heading = "Услуги";
